import { Container, Button, Form, Col, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";
import GoogleMap from "../components/GoogleMap";
import { useState } from "react";





function Iletisim() {
 
  
    const [formData, setFormData] = useState({
      name: "",
      surname:"",
      email: "",
      message: "",
    });
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (!formData.name || !formData.surname || !formData.email) {
        alert("Lütfen eksik alanları doldurunuz");
        return;
      }
      const updatedFormData = {

        name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
        surname: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
        email: formData.email,
        message: formData.message,
      };
      
    
      console.log(JSON.stringify(updatedFormData));

      fetch('http://localhost:3000/iletisim', {
        method: 'POST',
        body: JSON.stringify(formData)
      })
        event.target.reset();
        setFormData({
          name: "",
          surname: "",
          email:"",
          message: "",
        });
    
    };
  
    return (
      <div className="contact-form">
        <Container className="contact-container">
          <h1 className="mt-3 ">İLETİŞİM</h1>
          <Form className="mt-5 " onSubmit={handleSubmit} >
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridAd">
                <Form.Label>Ad:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Adınızı giriniz"
                  value={formData.name}
                  onChange={handleChange}
          
                />
              </Form.Group>
            </Row>
  
            <Form.Group className="mb-2" controlId="formGridSoyad">
              <Form.Label>Soyad:</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                placeholder="Soyadınızı giriniz"
                value={formData.surname}
                onChange={handleChange}
               
              />
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="formGridEmail">
              <Form.Label>E-mail:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="E-mail adresinizi giriniz"
                value={formData.email}
                onChange={handleChange}
              
              />
            </Form.Group>
  
            <Form.Group>
              <label htmlFor="exampleFormControlTextarea1" className="form-label">
                Mesaj:
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="İletmek istediğiniz konuyu giriniz"
                name="message"
                value={formData.subject}
                onChange={handleChange}
                
              ></textarea>
            </Form.Group>
            <br />
  
            <Button variant="primary" type="submit" className="submitButton" >
              Gönder
            </Button>
          </Form>
        </Container>
        <GoogleMap />
        <Footer />
        <Whatsapp />
      </div>
    );
  };
  
export default Iletisim;

