import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import tedarik from "../img/tedarik.jpg";
import lojistik from "../img/lojistik.jpg";

const TedarikLojistik = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={tedarik} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Tedarik Ve Lojistik</h1>
            <h3>Tedarik Nedir?</h3>

            <p>
              Tedarik yönetimi, bir kuruluşun ihtiyaç duyduğu mal ve hizmetlerin
              tedarik edilmesini, satın alınmasını ve yönetimini içerir. Bu,
              istikrarlı ve güvenilir bir malzeme tedariki sağlamak için
              tedarikçilerin seçilmesini, sözleşmelerin müzakere edilmesini ve
              tedarikçilerle ilişkilerin yönetilmesini içerir.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className=" mt-5 pt-5 ">
            <h3>Lojistik Nedir?</h3>
            <p>
              Lojistik, mal ve hizmetlerin fiziksel olarak taşınmasını ve
              depolanmasını içerir. Bu, ürünlerin varış noktalarına zamanında ve
              uygun maliyetli bir şekilde teslim edilmesini sağlamak için
              nakliye, depolama ve dağıtım faaliyetlerinin planlanması ve
              koordinasyonunu içerir.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img src={lojistik} alt="" className="pages-photo mt-5" />
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default TedarikLojistik;
