import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import yazilim2 from "../img/yazilim2.jpg";

const YazilimGelistirme = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={yazilim2} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Yazılım Geliştirme</h1>

            <p>
              Yazılım geliştirme, yazılım uygulamalarını tasarlama, oluşturma,
              test etme ve bakımını yapma sürecidir.İşletmeler için web ve mobil
              uygulamalar, masaüstü yazılımları, oyunlar ve kurumsal yazılımlar
              dahil olmak üzere çok çeşitli uygulamalar oluşturmak için yazılım
              kullanılır. Kurumunuz için gerekli olan yazılım ihtiyacını
              karşılamak için hizmet vermekteyiz.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default YazilimGelistirme;
