import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import bilisim from "../img/bilisim.png";

const BilisimDanismanlik = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={bilisim} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Bilişim Ve Danışmanlık</h1>

            <p>
              Bilişim, verilerin yönetimini, işlenmesini ve
              analizini içeren çözümler oluşturmak için teknolojiyi kullanmayı
              içerir.Kuruluşlar, bilgi sistemlerini en iyi şekilde nasıl
              uygulayacakları ve yönetecekleri konusunda bilinçli kararlar
              almalarına yardımcı olması için danışmanların uzmanlığına ihtiyaç
              duyabileceğinden, bilişim ve danışmanlık iç içedir. Semai Bilşiim olarak bilişim ve danışmanlık ihtiyaçlarınız için yanınızdayız.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default BilisimDanismanlik;
