import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import logo from "../img/logo.png";

const Whatsapp = () => {

	return (
		<WhatsAppWidget
			className="whatsapp"
			phoneNo="5412431789"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={true}
			autoOpenTimer={6000}
			messageBox={true}
			iconSize="40"
			iconColor="white"
			iconBgColor="#25D366"
			headerIcon={logo}
			headerIconColor="white"
			headerTxtColor="white"
			headerBgColor="#064180"
			headerTitle="Semai Bilişim ve Teknoloji"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatMessage={<>Merhaba👋 <br /><br /> Nasıl yardımcı olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesajınızı yazınız."
			btnBgColor="#064180"
			btnTxt="Sohbete Başla"
			btnTxtColor="white"
		/>
	

	);
};

export default Whatsapp;