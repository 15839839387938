import AboutUs from "../components/AboutUs";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import SocialMedia from "../components/SocialMedia";
import Whatsapp from "../components/Whatsapp";
import serviceData from "../data/serviceData";


const Home = () => {

  return (
    <>
      <Slider />
      <Cards data={serviceData} />
      <AboutUs/>
      <SocialMedia/>
      <Footer/>
      <Whatsapp/>
    </>
  );
};

export default Home;

