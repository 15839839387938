import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import yapayzeka from "../img/yapayzeka.jpg";

const YapayZeka = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={yapayzeka} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Yapay Zeka Teknolojileri</h1>

            <p>
              Yapay Zeka (AI) teknolojileri, makine öğrenimi, doğal dil işleme
              ve diğer teknikleri kullanarak insan zekasını ve karar verme
              süreçlerini taklit eden sistemler ve araçlardır.Yapay zeka teknolojileri,
              görsel algı, konuşma tanıma, karar verme ve dil çevirisi gibi
              tipik olarak insan zekası gerektiren görevleri gerçekleştirmek
              için kullanılır. Biz de Semai Bilişim ve Teknoloji olarak gerekli yapay zeka ihtiyaçlarınız için yanındayız.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default YapayZeka;
