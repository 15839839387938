import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
    <CardGroup className="footer-container">
      <Card className="footer-card">
        <Card.Body>
          <Card.Text>
            <h6>HİZMETLERİMİZ</h6>
            <br />

            <ul class="services">
              <li>
               <Link to="/askerihaberlesme">Askeri ve Sivil Haberleşme</Link>
              </li>
              <li>
              <Link to="/savunmasanayi">Savunma Sanayi Sistemleri</Link>
              </li>
              <li>
              <Link to="/elektroniksistem">Elektronik Sistemler</Link>
              </li>
              <li>
              <Link to="/bilisimdanismanlik">Bilişim Ve Danışmanlık</Link>
              </li>
              <li>
              <Link to="/teknikservis">Teknik Servis Ve Bakım</Link>
              </li>
              <li>
                <Link to="https://pos.param.com.tr/Tahsilat/Default.aspx?k=715965ce-33bf-4c2e-8d48-54b30082c9a7">ONLİNE ÖDEME İŞLEMİ</Link>
              
              </li>
              <li>
             <Link to="https://linkode.me/ARlSnNsp7w"> ALTERNATİF ÖDEME İŞLEMİ</Link>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="footer-card">
        <Card.Body>
          <Card.Text>
            <h6>ÇÖZÜMLERİMİZ</h6>
            <br />
            <ul class="services">
              <li>
              <Link to="/kosgebkobi">KOSGEB-KOBİ Danışmanlığı</Link>
              </li>
              <li>
              <Link to="/tesisguvenlik">Tesis Güvenlik Ve AFGM Belgelendirmesi</Link>
              </li>
              <li>
              <Link to="/yazilimgelistirme">Yazılım Geliştirme</Link>
              </li>
              <li>
              <Link to="/yapayzeka">Yapay Zeka Teknolojileri</Link>
              </li>
              <li>
              <Link to="/yerlimillilestirme">Yerli Ve Millileştirme</Link>
              </li>
              <li>
              <Link to="/tedariklojistik">Tedarik Ve Lojistik</Link>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="footer-card">
        <Card.Body>
          <Card.Text>
            <h6>ADRES </h6>
            <br />
            <ul class="adress">
              <li>
                <strong>OFİS: </strong>100.Yıl Bulvarı Prestij İş Merkezi No: 55
                A/35 YENİMAHALLE/ANKARA <br />
                <i class="fa-solid fa-phone"></i>
                <strong>Telefon:</strong> +90 (312)429 00 76 <br />{" "}
                <i class="fa-solid fa-fax"></i>
                <strong>Faks:</strong> +90 (312) 429 00 78 <br />{" "}
                <i class="fa-solid fa-mobile-retro"></i>
                <strong>GSM:</strong> +90 (541) 243 17 89 <br />
                <i class="fa-solid fa-envelope"></i>
                <strong>Email :</strong>
                info@semaibilisim.com
              </li>
            </ul>
            
          </Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
    <div className=" copy  text-center bg-light  ">
          Copyright &copy; Semai Bilişim Ve Teknoloji
        </div>
    </>
    
  
    
  );
}

export default Footer;
