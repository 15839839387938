import Carousel from 'react-bootstrap/Carousel';
import slider1 from '../img/slider1.jpg';
import slider2 from '../img/slider2.jpg';
import slider3 from '../img/slider3.jpg';
import slider4 from '../img/slider4.jpg';

function Slider() {
  return (
  
    <Carousel  variant="light" fade autoPlay={true} interval={2000}>
      <Carousel.Item >
        <img
          className="block w-100"
          src={slider1}
          alt="First slide"
        />
      
      </Carousel.Item>
      <Carousel.Item >
        <img
          className="block w-100"
          src={slider2}
          alt="Second slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="block w-100"
          src={slider3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="block w-100"
          src={slider4}
          alt="Fourth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;