import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png";
import { useState } from "react";

const NavScroll = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };
  
 

  return (
    <>
    <Navbar expand="lg" expanded={expanded} onToggle={handleNavToggle}
      onSelect={(eventKey) =>
        eventKey === "anasayfa" ? navigate("/") : navigate(`/${eventKey}`)
      }
    >
      <Container >
        <Navbar.Brand>
          <Nav.Link eventKey="anasayfa" className="nav nav-link">
            <img src={logo} alt=""  />SEMAİ BİLİŞİM VE TEKNOLOJİ
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" >
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100p"}}
            navbarScroll
          >
            <Nav.Link eventKey="anasayfa">Ana Sayfa</Nav.Link>
            <Nav.Link eventKey="kurumsal">Kurumsal</Nav.Link>
            <NavDropdown title="Hizmetlerimiz" id="navbarScrollingDropdown">
              <NavDropdown.Item eventKey="askerihaberlesme">
                Askeri Ve Sivil Haberleşme
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="savunmasanayi">
                Savunma Sanayi
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="elektroniksistem">
                Elektronik Sistemler
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="bilisimdanismanlik">
                Bilişim ve Danışmanlık
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="teknikservis">
                Teknik Servis ve Bakım
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Çözümlerimiz" id="navbarScrollingDropdown">
              <NavDropdown.Item eventKey="kosgebkobi">
              KOSGEB Ve KOBİ Danışmanlık
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="tesisguvenlik">
              Tesis Güvenlik ve AFGM Belgelendirmesi
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="yazilimgelistirme">
                Yazılım Geliştirme
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="yapayzeka">
                Yapay Zeka Teknolojileri
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="yerlimillilestirme">
                Yerli ve Millileştirme
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="tedariklojistik">
                Tedarik ve Lojistik
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link eventKey="iletisim">İletişim</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default NavScroll;

