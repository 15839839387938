import React from 'react'


const SocialMedia = () => {
  return (
    <div className="icon-container">
        <h3>SOSYAL MEDYADA BİZ</h3>
        <div className="social-icons">
                <a href="https://www.twitter.com"><i class="fab fa-twitter fa-3x"></i></a>
                <a href="https://www.facebook.com"><i class="fab fa-facebook fa-3x"></i></a>
                <a href="https://www.instagram.com"><i class="fab fa-instagram fa-3x"></i></a>
                <a href="https://www.linkedin.com"><i class="fab fa-linkedin fa-3x"></i></a>
            </div>
    </div>
  );
}

export default SocialMedia;