const data = [
  {
    id: 1,
    name: "Savunma Sanayi Sistemleri",
    link: "savunmasanayi",
    text: "Semai Bilişim olarak, Savunma Sanayi alanında İhtiyaç duyulan ürünler kapsamında yapılacak yenilik ve projelere katkı sağlamak üzere çalışmalar yapmaktayız.",
    img:  "https://i4.hurimg.com/i/hurriyet/75/750x422/5fe99aff0f25442794b1d545.jpg",
  },
  {
    id: 2,
    name: "Askeri Haberleşme Sistemleri",
    link: "askerihaberlesme",
    text: "Askeri haberleşmenin her alanında(kara,deniz,hava) milli ve yerli olarak;kesintisiz ve kaliteli hizmet İçin İnovasyon,üretim ve imalat, bakım,onarım ve teknik servis hizmeti sağlamaktayız..",
    img:  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHG5Y8oHuWAjVojv-FNT2s5vIthdj4dxOhSA&usqp=CAU",
  },
  {
    id: 3,
    name: "Elektronik Sistemler",
    link: "elektroniksistem",
    text: "Elektronik Sistem Tasarımı, Şematik Tasarımı, Baskıdevre(PCB) Tasarımı, ihtiyaç duyulan yazılımlar için yanınızdayız.",
    img: "https://fastelektronik.com/images/services/4790966069063-176-SMD.jpg",
  },
  {
    id: 4,
    name: " Bilişim ve Danışmanlık",
    link: "bilisimdanismanlik",
    text: "Hızla gelişmekte olan dijital medyanın avantajlarını nasıl kullanacağınız konusunda sizlere profesyonel danışmanlık hizmetleri sunuyoruz.",
    img:  "https://www.4quattro.com/image/kurumsal/teknoloji-ve-bilisim-40.jpg",
  },
  {
    id: 5,
    name: "Teknik Servis ve Bakım",
    link: "teknikservis",
    text: "Vinç, Kule, Forklif, Asansör ve diğer elektronik kontrol sistemlerine ait teknik servis ve bakım onarım hizmetleri verilir.",
    img:  "https://www.krkelektrik.com/resim/upload/sb2508.jpg",
  },
];

export default data;
