import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import elektroniksistem1 from "../img/elektroniksistem1.jpg";


const ElektronikSistem = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img
              src={elektroniksistem1}
              alt=""
              className="pages-photo mt-5 "
            />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Elektronik Sistemler</h1>

            <p>
              Semai Bilişim olarak, elektronik tasarim ve haberleşme sistemleri
              için özgün çözümler üretmektekteyiz.Elektronik Sistem Tasarımı,
              Şematik Tasarımı, Baskıdevre(PCB) Tasarımı gibi ihtiyaç duyulan
              yazılımlar için yanınızdayız.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default ElektronikSistem;
