import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import askerihaberlesme from "../img/askerihaberlesme.jpg";
import ozelsektorhaberlesme from "../img/ozelsektorhaberlesme.jpg";

const Haberlesme = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={askerihaberlesme} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 " >
            <h1>Askeri Haberleşme Sistemleri</h1>

            <p>
              Kara-Deniz-Hava-Jandarma-Sahil Güvenlik Komutanlıkları envarterinde
              bulunan HF-VHF bandı telsiz, uydu, bilgisayar ve ağ haberleşme
              sistemlerinin; Millileştirme, yenileme, üretim ve imalat
              projeleri, Bakım Onarım ve Teknik servis hizmetleri, alanlarında
              faaliyet göstermekteyiz.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className=" mt-5 pt-5 ">
            <h1>Kamu Ve Özel Sektör Haberleşme Sistemleri</h1>

            <p>
              Karayolları-AFAD-Sağlık Bakanlığı ve diğer kamu ve özel sektör
              envarterinde bulunan telsiz, uydu, bilgisayar ve ağ haberleşme
              sistemlerinin; Yenileme, üretim ve imalat projeleri, Bakım Onarım
              ve Teknik servis hizmetleri, alanlarında faaliyet göstermekteyiz.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img src={ozelsektorhaberlesme} alt="" className="pages-photo mt-5" />
          </Col>
        </Row>
      </Container>
      <SliderCards/>
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default Haberlesme;
