import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";
import bizkimiz from "../img/bizkimiz.jpg";
import vizyon from "../img/vizyon.jpg";
import misyon from "../img/misyon.png";


const Kurumsal = () => {
  return (
    <div>
      <Container>
        <h2 className="text-center">HAKKIMIZDA</h2>
        <br />
        <Row className=" d-flex mt-4 pt-5 " >
          <h4>Biz Kimiz?Neler Yapıyoruz?</h4>
          <Col >
            <p>
              Kamu ve özel sektörün ihtiyaç duyduğu "kara, hava ve deniz
              platformlarına yönelik sistemlerin haberleşme, yazılım, elektronik
              ve elektromekanik parçaların üretimi, bu platformlara montajı ve
              entegrasyonunun yapılmasını sağlayarak, savunma sanayisine ürün
              üretimi ve Ar-ge çalışmaları yaparak faaliyet göstermekteyiz.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img src={bizkimiz} alt="" className="photo-hakkimizda" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="d-flex mt-4 pt-5 ">
          <Col md={12} lg={6}>
            <img src={misyon} alt="" className="photo-hakkimizda " />
          </Col>
          
          <Col >

          <h4>Misyonumuz</h4>
            <p>
              Semai Bilişim Ve Teknoloji olarak; kamu ve özel sektörde, askeri
              ve sivil alanlarında kesintisiz ve kaliteli hizmetimiz ile katkı
              sağlamaktır.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <br /><br />
        <Row className="d-flex mt-4 pt-5  ">
        <h4 >Vizyonumuz</h4>
          <Col >
            <p>
              Gelişen ve değişen dünya pazarında; yenilikçi,geliştirilebilir
              ürün ve hizmet anlayışımızla, müşterilerimize en hızlı, en
              kaliteli hizmeti verebilmek ve müşteri memnuniyetini en üst
              seviyede tutarak saygın,güvenilir ve tercih edilen bir kuruluş
              olmayı hedeflemekteyiz.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img src={vizyon} alt="" className="photo-hakkimizda" />
          </Col>
        </Row>
      </Container>
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default Kurumsal;
