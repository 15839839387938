import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavScroll from "./components/NavScroll";
import Anasayfa from "./pages/Anasayfa";
import Iletisim from "./pages/Iletisim";
import KosgebKobi from "./pages/KosgebKobi";
import Kurumsal from "./pages/Kurumsal";
import Haberlesme from "./pages/Haberlesme";
import SavunmaSanayi from "./pages/SavunmaSanayi";
import ElektronikSistem from "./pages/ElektronikSistem";
import BilisimDanismanlik from "./pages/BilisimDanismanlik";
import TeknikServis from "./pages/TeknikServis";
import TesisGuvenlik from "./pages/TesisGuvenlik";
import YazilimGelistirme from "./pages/YazilimGelistirme";
import YapayZeka from "./pages/YapayZeka";
import YerliMillilestirme from "./pages/YerliMillilestirme";
import TedarikLojistik from "./pages/TedarikLojistik";




function App() {

  return (
 

      <BrowserRouter>
        <NavScroll />
        <Routes>
          <Route exact path="/" element={<Anasayfa />} />
          <Route path="/kurumsal" element={<Kurumsal />} />
          <Route path="/iletisim" element={<Iletisim/>} />
          <Route path="/askerihaberlesme" element={<Haberlesme />}/>
          <Route path="/savunmasanayi" element={<SavunmaSanayi  />}/>
          <Route path="/elektroniksistem" element={<ElektronikSistem />}/>
          <Route path="/bilisimdanismanlik" element={<BilisimDanismanlik />}/>
          <Route path="/teknikservis" element={<TeknikServis />}/>
          <Route path="/kosgebkobi" element={<KosgebKobi/>}/>
          <Route path="/tesisguvenlik" element={<TesisGuvenlik/>}/>
          <Route path="/yazilimgelistirme" element={<YazilimGelistirme/>}/>
          <Route path="/yapayzeka" element={<YapayZeka/>}/>
          <Route path="/yerlimillilestirme" element={<YerliMillilestirme/>}/>
          <Route path="/tedariklojistik" element={<TedarikLojistik/>}/>
          
         
        </Routes>
      </BrowserRouter>
      
  
  );
 
}

export default App;

