
function GoogleMap() {
    return (
      <div className="map">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.7471890148695!2d32.74374671524329!3d39.96940467941965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d330bc957e1eb9%3A0x63868514e1d42bb0!2zU0VNQcSwIELEsEzEsMWexLBNIFRFS05PTE9KxLAgVkUgR8OcVkVOTMSwSw!5e0!3m2!1sen!2sus!4v1678358851521!5m2!1sen!2sus"
          width="100%" 
          height="450"
          style={{ border: 0 , marginBottom : 0 , marginTop: 25 }}
          allowFullScreen=""
          loading="lazy"
        />
      </div>
    );
  }
  
  export default GoogleMap;