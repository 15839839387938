import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import savunmasanayi1 from "../img/savunmasanayi1.png";
import savunmasanayi2 from "../img/savunmasanayi2.png";

const SavunmaSanayi = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img
              src={savunmasanayi1}
              alt=""
              className="pages-photo "
            />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Savunma Sanayi Sistemleri</h1>

            <p>
              Savunma sanayi ürünleri, silahlar, toplar, mühimmat, füzeler,
              askerî uçak, askerî araçlar, gemi, elektronik sistemler, gece
              görüş cihazları, holografik silah nişangahları, lazer mesafe
              ölçerler, lazer nişangahlar, el bombaları, kara mayınları ve daha
              fazlasını içermektedir.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className=" mt-5 pt-5 ">

            <p>
              Semai Bilişim olarak, savunma sanayi ihtiyaçları kapsamında askeri ve sivil firmaların
              Ar-Ge ve  inovasyon projelerine katkı sağlamaktayız. Bu
              kapsamda Ar-ge ve iş geliştirme ofisimizle gücümüze güç katıp
              Endüstri 4.0 teknolojisinde yerimizi aldık.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={savunmasanayi2}
              alt=""
              className="pages-photo"
            />
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default SavunmaSanayi;
