import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

function AboutUs() {
  return (
    <CardGroup className="about-container">
      <Card className="about-card">
        <Card.Body>
          {/* <i class="fa-solid fa-people-group "></i> */}
          <Card.Title>BİZ KİMİZ?</Card.Title>
          <Card.Text>
            Kamu ve özel sektörün ihtiyaç duyduğu "kara, hava ve deniz platformlarına yönelik
            sistemlerin haberleşme, yazılım, elektronik ve elektromekanik
            parçaların üretimi, bu platformlara montajı ve entegrasyonunun
            yapılmasını sağlayarak, savunma sanayisine ürün üretimi ve Ar-ge
            çalışmaları yaparak faaliyet göstermekteyiz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="about-card">
        <Card.Body>
          {/* <i class="fa-solid fa-puzzle-piece"></i> */}
          <Card.Title>MİSYON</Card.Title>
          <Card.Text>
            Semai Bilişim Ve Teknoloji olarak; kamu ve özel sektörde, askeri ve
            sivil alanlarında kesintisiz ve kaliteli hizmetimiz ile katkı
            sağlamaktır.{" "}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="about-card">
        <Card.Body>
          {/* <i class="fa-solid fa-paper-plane "></i> */}
          <Card.Title>VİZYON</Card.Title>
          <Card.Text>
            Gelişen ve değişen dünya pazarında; yenilikçi,geliştirilebilir ürün
            ve hizmet anlayışımızla, müşterilerimize en hızlı, en kaliteli
            hizmeti verebilmek ve müşteri memnuniyetini en üst seviyede tutarak
            saygın,güvenilir ve tercih edilen bir kuruluş olmayı
            hedeflemekteyiz.
          </Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
  );
}

export default AboutUs;
