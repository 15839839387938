import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import ofis from "../img/ofis.jpg";
import prestijarge from "../img/prestijarge.jpg";
import elektronik from "../img/elektronik.jpg";
import pcb from "../img/pcb.png";
import ortam from "../img/ortam.jpg";
import esp32devkitC from "../img/esp32devkitC.jpg";




export default class SliderCards extends Component {
  render() {
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      speed:5000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      pauseOnHover: true,
      

      
    
    };
    return (
      <div className="bizden-kareler" >
        <h4 className="text-center m-5 "> BİZDEN KARELER</h4>
        <Slider {...settings} >
            
          <div className="card-slider" >
          <img src={ofis} alt="" className="slider-img" />
          </div>
          <div className="card-slider" >
          <img src={ortam} alt="" className="slider-img" />
          </div>
          <div className="card-slider" > 
           <img src={elektronik} alt="" className="slider-img" />
          </div>
          <div className="card-slider" >
          <img src={pcb} alt="" className="slider-img" />
          </div>
          <div className="card-slider" >
          <img src={prestijarge} alt="" className="slider-img" />
          </div>
          <div className="card-slider" >
          <img src={esp32devkitC} alt=""  className="slider-img"/>
          </div>
              
        </Slider>
      </div>
    );
  }
}