import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import teknikservis from "../img/teknikservis.jpeg";

const TeknikServis = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={teknikservis} alt="" className="pages-photo mt-5 " />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Teknik Servis Ve Bakım</h1>

            <p>
              Teknik servis, teknik ürünler, sistemler veya ekipmanlar için
              destek ve bakım hizmetlerinin sağlanmasını ifade eder. Bu
              hizmetler, teknik ürünlerin kullanım ömürleri boyunca verimli,
              etkili ve güvenilir bir şekilde çalışmasını sağlamak için
              tasarlanmıştır.Teknik servis tipik olarak arızaları teşhis etmeyi
              ve onarmayı, rutin bakım yapmayı, yükseltmeleri ve güncellemeleri
              yüklemeyi ve kullanıcılara teknik destek sağlamayı içerir.İşletmemiz tarafından Vinç,
              Kule, Forklif, Asansör ve diğer elektronik kontrol sistemlerine
              ait teknik servis ve bakım onarım hizmetleri vermekteyiz.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default TeknikServis;
