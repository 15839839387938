import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import yerlilestime from "../img/yerlilestime.jpg";

const YerliMillilestirme = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img
              src={yerlilestime}
              alt=""
              className="pages-photo mt-5 "
            />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Yerli ve Millileştirme</h1>

            <p>
              Yerli ve millileştirme, özel malları milli bir hükûmet veya devlet adı
              altında devlet mülkiyeti vererek kamusal mala dönüştürme sürecine
              denir. Dışa bağımlılığı ve tedarik risklerini azaltmak,
              mevcut ürün ve sistemlerindeki yerli malzeme oranını artırmak
              amacıyla millileştirme faaliyetleri gerçekleştirmektedir.
            </p>
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default YerliMillilestirme;
