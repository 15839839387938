import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import kosgeb from "../img/kosgeb.jpg";
import kobi from "../img/kobi.jpg";

const KosgebKobi = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img src={kosgeb} alt="" className="pages-photo" />
          </Col>
          <Col className="mt-5 pt-5">
            <h1>KOSGEB-KOBİ DANIŞMANLIĞI</h1>

            <p>
              Semai Bilişim ve Teknoloji olarak Girişimcilik, Ar-Ge, Teknolojik
              Üretim ve Yerlileştirme, İşletme Geliştirme, Büyüme ve
              Uluslararasılaşma, KOBİ Finansman Destekleri kapsamında
              Girişimcilere, işletmelere veya kurumlara çeşitli danışmanlık
              hizmetleri sunmaktayız.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="mb-4 mt-3 ">
            <h3>KOSGEB NEDİR?</h3>

            <p>
              Ülkemizin ekonomik ve sosyal ihtiyaçlarının
              karşılanmasında küçük ve orta ölçekli işletmelerin payını ve
              etkinliğini artırmak, rekabet güçlerini ve düzeylerini yükseltmek,
              sanayide entegrasyonu ekonomik gelişmelere uygun biçimde
              gerçekleştirmek amacıyla T.C. Sanayi ve Ticaret Bakanlığı Küçük ve
              Orta Ölçekli İşletmeleri Geliştirme ve Destekleme İdaresi
              Başkanlığı (KOSGEB) kurulmuştur. KOSGEB Destek Programları’nın
              amacı; ülkenin ekonomik ve sosyal ihtiyaçlarının karşılanmasında,
              küçük ve orta ölçekli işletmelerin payını ve etkinliğini artırmak,
              rekabet güçlerini ve düzeylerini yükseltmek, ekonomik gelişmelere
              uygun bir şekilde sanayide entegrasyonu gerçekleştirmek,
              ihracattaki paylarını artırmak, araştırma-geliştirme, yenilik ve
              işbirliği faaliyetlerini desteklemek ve girişimcilik kültürünü
              geliştirmektir.
            </p>
          </Col>
          <Col md={12} lg={6} >
            <img src={kobi} alt="" className="pages-photo " />
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default KosgebKobi;
