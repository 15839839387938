import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import SliderCards from "../components/SliderCards";
import Whatsapp from "../components/Whatsapp";
import tesisguvenlik from "../img/tesisguvenlik.jpg";
import afgm from "../img/afgm.jpg";

const TesisGuvenlik = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <img
              src={tesisguvenlik}
              alt=""
              className="pages-photo mt-5 "
            />
          </Col>
          <Col className=" mt-5 pt-5 ">
            <h1>Tesis Güvenlik Ve AFGM Belgelendirmesi</h1>
            <h3>Tesis Güvenlik Belgesi Nedir?</h3>

            <p>
              Tesis Güvenlik Belgesi; bir tesiste bulunan veya bulunabilecek
              gizlilik dereceli bilgi, belge, proje ve malzemenin fiziki
              güvenliklerinin sağlanması için, tesisin bulunduğu yer ve çevre
              şartları ile maruz kalabileceği dış ve iç tehditler göz önüne
              alınarak projelendirilmiş olan koruma önlemlerinin Milli Savunma
              Bakanlığı Tarafından denetlenerek uygun bulunduğunu belirten ve bu
              kurallara uyan firmaya verilen belgeye denir.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className=" mt-5 pt-5 ">
            <h3>AFGM Belgelendirmesi Nedir?</h3>

            <p>
              Askeri Fabrikalar Genel Müdürlüğü (AFGM)'ye bağlı 26 fabrika ve 3
              tersane mevcuttur. Söz konusu fabrikalar için gerekli mal alımları
              onaylı tedarikçi havuzunda bulunan firmalardan yapılmaktadır.
              Onaylı tedarikçi adı altında başvurularını tamamlamış Askeri
              Fabrikalar Genel Müdürlüğü tarafından denetlenmiş ve bu
              sertifikayı almaya hak kazanmış firmalardan direkt olarak hizmet
              ve ürün alımı yapmaktadır.
            </p>
          </Col>
          <Col md={12} lg={6}>
            <img src={afgm} alt="" className="pages-photo mt-5" />
          </Col>
        </Row>
      </Container>
      <SliderCards />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default TesisGuvenlik;
